<section id="testimonial" class="testimonial-area ptb-100 bg-image">
    <div class="container">
        <div class="section-title">
            <h2>Referencje <span style="color: #0d2b50;">od klientów</span></h2>
        </div>
        <div class="testimonial-slides owl-carousel owl-theme">
            <div class="testimonial-item">
                <div class="testimonial-content">
                    <div class="desc">
                        <p>cudowna Groomerka 😊 Nasz Boo uwielbia chodzić na zabiegi i zawsze wychodzi szczęśliwy ♥️🐾</p>
                    </div>
                    <div class="client-info">
                        <h3>Alicja W.</h3>
                    </div>
                </div>
            </div>
            <div class="testimonial-item">
                <div class="testimonial-content">
                    <div class="desc">
                        <p>Wspaniała osoba. Polecam. Maksiu bardzo szczęśliwy.</p>
                    </div>
                    <div class="client-info">
                        <h3>Iwona M.</h3>
                    </div>
                </div>
            </div>
            <div class="testimonial-item">
                <div class="testimonial-content">
                    <div class="desc">
                        <p>Najlepsza ☺️ polecam z całego serduszka</p>
                    </div>
                    <div class="client-info">
                        <h3>Angelika K.</h3>
                    </div>
                </div>
            </div>
            <div class="testimonial-item">
                <div class="testimonial-content">
                    <div class="desc">
                        <p>Bardzo miła i profesjonalna obsluga.Zwierzak zadowolony ja też 🐕gorąco polecam😀</p>
                    </div>
                    <div class="client-info">
                        <h3>Pokussa P.</h3>
                    </div>
                </div>
            </div>
            <div class="testimonial-item">
                <div class="testimonial-content">
                    <div class="desc">
                        <p>Szczerze polecam!❤
                            Psinka po wizycie piekna i zadowolona😊</p>
                    </div>
                    <div class="client-info">
                        <h3>Gabriela B.</h3>
                    </div>
                </div>
            </div>
            <div class="testimonial-item">
                <div class="testimonial-content">
                    <div class="desc">
                        <p>Polecam bardzo. Wszyscy zadowoleni,Reksio najbardziej 😁🥰</p>
                    </div>
                    <div class="client-info">
                        <h3>Aga M.</h3>
                    </div>
                </div>
            </div>
            <div class="testimonial-item">
                <div class="testimonial-content">
                    <div class="desc">
                        <p>polecam z całego serca, Holly🐶 piękna i zadowolona napewno wrócimy 🙂</p>
                    </div>
                    <div class="client-info">
                        <h3>Monika K.</h3>
                    </div>
                </div>
            </div>
            <div class="testimonial-item">
                <div class="testimonial-content">
                    <div class="desc">
                        <p>Pani ma wspaniałe podejście do zwierzaczków, pięknie strzyże, piesek wyszedł zadbany kompleksowo, zadowolony i wyglądał prześlicznie. Bardzo polecam 😍</p>
                    </div>
                    <div class="client-info">
                        <h3>Gabriela G.</h3>
                    </div>
                </div>
            </div>
            <div class="testimonial-item">
                <div class="testimonial-content">
                    <div class="desc">
                        <p>Piesek piękny i pachnący 🥰🥰 Jestem pod wrażeniem. Polecam z całego serca ❣️❣️
                        </p>
                    </div>
                    <div class="client-info">
                        <h3>Monika C.</h3>
                    </div>
                </div>
            </div>
            <div class="testimonial-item">
                <div class="testimonial-content">
                    <div class="desc">
                        <p>Helena jest świetna, super podejście do zwierzaka, pełen profesjonalizm,  gorąco polecam 🙂
                        </p>
                    </div>
                    <div class="client-info">
                        <h3>Agnieszka Ż.</h3>
                    </div>
                </div>
            </div>
            <div class="testimonial-item">
                <div class="testimonial-content">
                    <div class="desc">
                        <p>Polecam w 100%
                            Moj piesek zawsze slicznie obcięty ❤🐶
                        </p>
                    </div>
                    <div class="client-info">
                        <h3>Justyna M.</h3>
                    </div>
                </div>
            </div>
            <div class="testimonial-item">
                <div class="testimonial-content">
                    <div class="desc">
                        <p>Z całego serca polecam!
                            Takiego podejścia do pieska nie spotkałam nigdzie, pełen profesjonalizm.
                            Dziękujemy za cenne rady oraz za to że spotkaliśmy na naszej drodze tak wspaniałą Groomerke❤️
                        </p>
                    </div>
                    <div class="client-info">
                        <h3>Katarzyna P.</h3>
                    </div>
                </div>
            </div>
            <div class="testimonial-item">
                <div class="testimonial-content">
                    <div class="desc">
                        <p>Gorąco polecam ♥️ Pełny profesjonalizm. Jesteśmy bardzo zadowoleni, a piesek szczęśliwy 🐕♥️👌
                        </p>
                    </div>
                    <div class="client-info">
                        <h3>Magdalena G.</h3>
                    </div>
                </div>
            </div>
            <div class="testimonial-item">
                <div class="testimonial-content">
                    <div class="desc">
                        <p>Piesek wyszedł zadowolony i piękny 🙂 profesjonalne podejście do klienta i pupila. Zdecydowanie polecam</p>
                    </div>
                    <div class="client-info">
                        <h3>Paulina K.</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>