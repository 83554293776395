<div class="main-banner item-bg1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content text-center">
                    <img class="main-logo" src="assets/img/logo.svg" alt="logo">
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> -->
                    <!-- <div class="btn-box">
                        <a href="#about" class="btn btn-primary">Get Started</a>
                        <span>or</span>
                        <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="icofont-ui-play"></i> Watch Video</a>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <app-features></app-features> -->

<app-about></app-about>

<app-why-we-different></app-why-we-different>

<app-cta></app-cta>

<!-- <app-services></app-services> -->

<app-testimonial></app-testimonial>

<app-blog></app-blog>

<app-contact></app-contact>