import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-demo-sidebar',
  templateUrl: './demo-sidebar.component.html',
  styleUrls: ['./demo-sidebar.component.scss']
})
export class DemoSidebarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  goTo(destination: string) {
    if(destination === "fb") {
      window.open("https://www.facebook.com/groomerhelena", '_blank');
    }
    if(destination === "insta") {
      window.open("https://www.instagram.com/groomer_helena", '_blank');
    }
    if(destination === "tiktok") {
      window.open("https://www.tiktok.com/@groomer_helena", '_blank');
    }
  }

}
