<section class="page-title-area item-bg1">
    <div class="container">
        <h2>Żądana strona nie istnieje</h2>
    </div>
</section>

<section class="error-area ptb-100">
    <div class="container">
        <div class="error-content">
           
        </div>
    </div>
</section>