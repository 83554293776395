<section class="why-we-different ptb-100 bg-image" id="different">
    <div class="container">
        <div class="section-title">
            <!-- <span>Highly Creative Solutions</span> -->
            <h2>Usługi</h2>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> -->
        </div>
        <div class="tab">
            <ul class="tabs">
                <li><a href="#">
                    Kąpiel
                </a></li>
                <li><a href="#">
                    Strzyżenie
                </a></li>
                <li><a href="#">
                    Trymowanie
                </a></li>
                <li><a href="#">
                    Wyczesywanie/rozczesywanie
                </a></li>
                <li><a href="#">
                    Skracanie pazurów
                </a></li>
                <li><a href="#">
                    Czyszczenie uszu
                </a></li>
            </ul>
            <div class="tab_content">
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12 why-we-different-content">
                            <div class="tabs_item_content">
                                <!-- <h3>Speed , Flexibility</h3> -->
<p>
    Kąpiel jest to nieodłączny element wizyty pieska w moim salonie. Zawsze wykonywana jest z
dedykowanymi kosmetykami dla psów, które dopieram do potrzeb skóry i sierści. Szeroka gama
szamponów i odżywek renomowanych marek sprawia że kąpiele mogą być nawilżające, odżywiające,
odbudowujące, hipoalergiczne. Marki ,które ja polecam i używam w salonie to:
</p>
                                <ul>
                                    <li>Best Shot</li>
                                    <li>Double K</li>
                                    <li>Megamazon</li>
                                    <li>PSH</li>
                                    <li>Natures Specialties</li>
                                    <li>Botaniqa</li>
                                    <li>BioGroom</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 why-we-different-img">
                            <div class="tabs_item_img">
                                <img src="assets/img/kapiel.jpeg" alt="img">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12 why-we-different-img">
                            <div class="tabs_item_img">
                                <img src="assets/img/strzyzenie.jpeg" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 why-we-different-content">
                            <div class="tabs_item_content">
                                <p>Strzyżenie dzielę na dwa typy. Pierwsze to najczęściej strzyżenie według życzenia właściciela.
                                    Przeprowadzam zawsze w tym przypadku szczegółową rozmowę odnośnie wyglądu końcowego
                                    fryzury.
                                    Drugi typ to strzyżenie według wzorca FCI dotyczy to psów rasowych. Wzorce jak ma wyglądać
                                    fryzura danej rasy określana jest przez Miedzynarodową Federację Kynologiczną.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12 why-we-different-content">
                            <div class="tabs_item_content">
                                <p>Trymowanie jest to nic innego jak ręczne usuwanie(wyrywanie jest bezbolesne dla psa) martwego
                                    włosa czyli włosa okrywowego. Trymowanie dotyczy jedynie niektórych ras bądź kundelków w typie ras do trymowania. Jeśli szata
                                    pieska nadaje się do trymowania to informuję o tym na wizycie po obejrzeniu pieska i jego szaty.</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 why-we-different-img">
                            <div class="tabs_item_img">
                                <img src="assets/img/trymowanie.jpeg" alt="img">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12 why-we-different-img">
                            <div class="tabs_item_img">
                                <img src="assets/img/wyczesywanie.jpeg" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 why-we-different-content">
                            <div class="tabs_item_content">
                                <p>Wyczesywanie/rozczesywanie dotyczy wszystkich ras oraz mixów. Wyczesanie podszerstka oraz
                                    rozczesywanie kołtunów odpowiednimi narzędziami do tego przeznaczonymi.
                                    W prostym skrócie wyczesany podszerstek u mnie to dużo mniej włosów w Twojej kawie, na dywanie
                                    i na ubraniach.
                                    Rozczesywanie kołtunów to nic innego jak usunięcie skołtunionego, zbitego, sklejonego włosa ,który
                                    krępuje ruchy psa i powoduje jego dyskomfort w sposób jak najbardziej bezbolesny dla psa.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12 why-we-different-img">
                            <div class="tabs_item_img">
                                <img src="assets/img/pazurki.jpeg" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 why-we-different-content">
                            <div class="tabs_item_content">
                                <p>Skracanie pazurów jest bardzo ważnym zabiegiem, nieskracanie pazurów u psa powoduje wrastanie
                                    pazura w opuszki co powoduje ból i dyskomfort, krzywienie łapek ponieważ przerośnięte zwijają się
                                    uniemożliwiając poprawne stawianie łapek przez psa . Po skróceniu pazura nie naruszając jego
                                    rdzenia staram się również go opiłować aby manicure był elegancki.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12 why-we-different-img">
                            <div class="tabs_item_img">
                                <img src="assets/img/uszy.jpeg" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 why-we-different-content">
                            <div class="tabs_item_content">
                                <p>Czyszczenie uszu czyli usuwanie wydzieliny, woskowiny, brudu zbierającego się na i wewnątrz ucha
                                    psa odpowiednimi produktami . Ważne do tego punktu również dochodzi depilacja uszu. Depilację
                                    uszu wykonywana przy każdej wizycie u ras bądź miksów, które mają wrastający włos we wnętrzu
                                    
                                    ucha również produktem do tego przeznaczonym. Nie depilowanie uszu psa może doprowadzić do
                                    infekcji oraz zapalenia ucha. Dobrze wykonana depilacja jest bezbolesna.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>