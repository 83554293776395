<section id="services" class="services-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Cennik</h2>
            <p>W cenę pielęgnacji wchodzi kąpiel, strzyżenie, wyczesanie, skracanie pazurów, czyszczenie uszu (usuwanie wrastających włosów).</p>
            <p>Podane ceny są cenami orientacyjnymi.</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/shihtzu.png" alt="image">
                        <div class="icon">
                        </div>
                    </div>
                    <div class="services-content">
                        <h3 class="dog hideme">Shih tzu</h3>
                        <p class="price">100-120 zł</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/york.png" alt="image">
                    </div>
                    <div class="services-content">
                        <h3 class="dog">York</h3>
                        <p class="price">100 zł</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/maltanczyk.png" alt="image">
                    </div>
                    <div class="services-content">
                        <h3 class="dog">Maltańczyk</h3>
                        <p class="price">100 zł</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/Bischonfrise.png" alt="image">
                    </div>
                    <div class="services-content">
                        <h3 class="dog">Bischon frise</h3>
                        <p class="price">130 zł</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/Szpicminiatura.png" alt="image">
                    </div>
                    <div class="services-content">
                        <h3 class="dog">Szpic miniatura</h3>
                        <p class="price">120 zł</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/Szpicsredni.png" alt="image">
                    </div>
                    <div class="services-content">
                        <h3 class="dog">Szpic średni</h3>
                        <p class="price">140 zł</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/whiteTerrier.png" alt="image">
                    </div>
                    <div class="services-content">
                        <h3 class="dog">West Highland white terrier</h3>
                        <p class="price">130-150 zł</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/Cavalier.png" alt="image">
                    </div>
                    <div class="services-content">
                        <h3 class="dog">Cavalier King Charles Spaniel</h3>
                        <p class="price">120 zł</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/CockerSpaniel.png" alt="image">
                    </div>
                    <div class="services-content">
                        <h3 class="dog">Cocker Spaniel</h3>
                        <p class="price">150 zł</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/Foksterier.png" alt="image">
                    </div>
                    <div class="services-content">
                        <h3 class="dog">Foksterier</h3>
                        <p class="price">150 zł</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/Husky.png" alt="image">
                    </div>
                    <div class="services-content">
                        <h3 class="dog">Husky</h3>
                        <p class="price">150zł</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/Retriver.png" alt="image">
                    </div>
                    <div class="services-content">
                        <h3 class="dog">Golden Retriver, Border Collie</h3>
                        <p class="price">180 zł</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/Akita.png" alt="image">
                    </div>
                    <div class="services-content">
                        <h3 class="dog">Akita</h3>
                        <p class="price">180-200zł</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/Samoyed.png" alt="image">
                    </div>
                    <div class="services-content">
                        <h3 class="dog">Samoyed, Chow Chow</h3>
                        <p class="price">200zł</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/Airedale.png" alt="image">
                    </div>
                    <div class="services-content">
                        <h3 class="dog">Airedale Terrier</h3>
                        <p class="price">200-250 zł</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/Berneńczyk.png" alt="image">
                    </div>
                    <div class="services-content">
                        <h3 class="dog">Berneńczyk</h3>
                        <p class="price">250zł</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/Nowofundland.png" alt="image">
                    </div>
                    <div class="services-content">
                        <h3 class="dog">Nowofundland</h3>
                        <p class="price">300-350 zł</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/PudelToy.png" alt="image">
                    </div>
                    <div class="services-content">
                        <h3 class="dog">Pudel</h3>
                        <p class="price">toy, miniatura 120zł, średni 140zł, duży 200zł</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/Sznaucer.png" alt="image">
                    </div>
                    <div class="services-content">
                        <h3 class="dog">Sznaucer</h3>
                        <p class="price">mini 130zł, średni 150zł, duży 200-250zł</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/krótkowłose.png" alt="image">
                    </div>
                    <div class="services-content">
                        <h3 class="dog">Małe rasy krótkowłose</h3>
                        <p class="price">60-80zł</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/malshi.png" alt="image">
                    </div>
                    <div class="services-content">
                        <h3 class="dog">Kundelki i mix małe typu maltipoo, yokiepoo, lhasapoo, malshi</h3>
                        <p class="price">100-120 zł</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/Kundelki.png" alt="image">
                    </div>
                    <div class="services-content">
                        <h3 class="dog">Kundelki i mix średnie powyżej 5 kg</h3>
                        <p class="price">130 zł</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/rottweiler.png" alt="image">
                    </div>
                    <div class="services-content">
                        <h3 class="dog">Duże rasy krótkowłose typu lablador, cane corso, dalmatyńczyk, rottweiler</h3>
                        <p class="price">150 zł</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/Owczarki.png" alt="image">
                    </div>
                    <div class="services-content">
                        <h3 class="dog">Owczarki ras długowłosych</h3>
                        <p class="price">200-250 zł</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/kąpiel.png" alt="image">
                    </div>
                    <div class="services-content">
                        <h3 class="dog">Tylko kąpiel z wyczesaniem małe rasy</h3>
                        <p class="price">80 zł</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="other">
            <div><span class="dot">●</span>Masz inną rasę niż w cenniku, napisz do mnie.</div>
            <div><span class="dot">●</span>Bardzo zaniedbane psy wycena indywidualna.</div>
            <div><span class="dot">●</span>Rozczesywanie niestandardowych kołtunów, usuwanie filcu to koszt 70 zł za godzinę pracy i jest doliczany do ceny podstawowej usługi.</div>
            <div><span class="dot">●</span>Nie przyjmuję psów bez kąpieli lub kąpanych w domu.</div>
            <div><span class="dot">●</span>Psy agresywne 100 zł za godzinę pracy niezależnie od rasy i wielkości psa.</div>
        </div>
    </div>
</section>