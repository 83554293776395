<section class="cta-area" id="cta">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-8">
                <div class="cta-content">
                    <h3><b>Regulamin salonu</b></h3>
                    <br>
                    <p>● 🛁✂️Pielęgnacja małych ras to około 2 godziny , średnich i dużych od 3 godzin nawet do 4-5 w zależności od rasy, dodatkowych niespodzianek (kołtuny, filce)</p>
                    <p>● ⭐️Usługa Express + 100% ceny podstawowej  (📆termin poza kolejką, jeszcze tego samego dnia lub w po godzinach pracy ) 
                    </p>
                    <p>● 🕘Pracuję od poniedziałku do piątku 9-18  oraz niektóre soboty od 9-13 ❗️( o godzinie 16:00 przyjmuję ostatniego klienta, który jest do odbioru około godziny 18:00 w przypadku małych ras i średnich ) 
                    </p>
                    <p>● Uprzejmie proszę o punktualne przyprowadzanie piesków i odbieranie ich. ⏱
                    </p>
                    <p>● 📆Termin na wizytę Twojego pupila zarezerwujesz przez 📩 📞 ( preferowane wiadomości SMS, Facebook, Instagram) bądź telefonicznie (podczas pracy nie zawsze mam czas na odebranie telefonu ale w wolnej chwili oddzwonię).Przy rezerwacji proszę o informację na temat stanu szaty i zdrowia pieska. ❗️W przypadku odwołania wizyty proszę o jak najszybszy kontakt, będzie mi miło💕 jeśli poinformujesz mnie minimum dzień wcześniej.</p>
                    <p>● W miarę możliwości będę dzień wcześniej wysyłać do was wiadomość przypominającą o wizycie. 📩
                    </p>
                    <p>● ❗️Klienci rezerwujący wizytę i nie pojawiający się (bez kontaktu) wpisuje na 🖤📋, a przy ponownej rezerwacji przez taką osobę pobieram zaliczkę przelewem na konto 50% cenny podstawowej za usługę.
                    </p>
                    <p>● Jeśli to pierwsza wizyta u mnie bądź pierwsza wizyta szczeniaczka proszę poinformuj mnie o tym.
                    </p>
                    <p>● ❗️NIE podaję żadnych środków uspokajających.💊
                    </p>
                    <p>● 🛁Pracuję tylko na profesjonalnych  kosmetykach dla psów marek Best Shot, Megamazon, Double K, Natures Specialties, Botaniqa 🧴
                    </p>
                    <p>● Bezpośrednio przed wizytą zapewnij pupilowi dłuższy spacer w celu załatwienia wszystkich potrzeb fizjologicznych.🦮
                    </p>
                    <p>● Nie przyjmuję kotów 🐈‍⬛ oraz psów agresywnych.
                    </p>
                    <p>● Wszystkie pielęgnacje wykonuję bez obecności właściciela. Pod nieobecność właściciela pies jest spokojniejszy i chętniej współpracuje ze mną. Salon prowadzę sama więc przyjmuję tylko jednego pieska na konkretną godzinę i to daje totalny spokój i ciszę podczas wizyty 🐩❗️Właściciel może towarzyszyć przy pielęgnacji ale pielęgnacja taka będzie trwa 1-2 godziny dłużej niż standardowa pielęgnacja (ok. 2 h) i kosztowała +100% ceny podstawowej za pielęgnacje Twojego psa. </p>
                    <p>● W moim salonie wizyta obejmuje wyłącznie kompleksową pielęgnację psów – kąpiel🛁, strzyżenie✂️
                    </p>
                    <p>● ❗️Nie ma możliwości umówienia się z pieskiem jedynie na samo strzyżenie bez kąpieli. 
                    </p>
                    <p>● Nie ponoszę odpowiedzialności za pogorszenie stanu zdrowia psa, którego właściciel zataił istnienie chorób:  ❗️padaczki, chorób serca, skóry a pieski starsze, chore, po zabiegach, bojaźliwe oraz niezrównoważone psychicznie strzygę jedynie na żądanie właściciela i na jego odpowiedzialność.
                    </p>
                    <p>● W przypadku bardzo mocno skołtunionej okrywy wcześniej ustalona fryzura może ulec zmianie- liczy się przede wszystkim dobro i komfort psa.
                    </p>
                    <p>● Nie golę psiaków do skóry ani nie strzygę psów z podwójną okrywą włosową.
                    </p>
                    <p>● W przypadku przyprowadzenia psa z insektami obowiązkowo wykonuję odpchlenie bez wcześniejszego uzgodnienia z właścicielem i doliczam +30 zł do ceny podstawowej 
                    </p>
                    <p>● Pieski muszą posiadać aktualne szczepienie przeciwko wściekliźnie. 
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>