import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router, NavigationEnd, ActivatedRoute, UrlSegment  } from '@angular/router';
import { filter } from 'rxjs/operators';


@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    showNavbar: boolean = true;

    constructor(private viewportScroller: ViewportScroller, private router: Router, route: ActivatedRoute) {
        this.router.events.subscribe(
            (event: any) => {
              if (event instanceof NavigationEnd) {
               this.showNavbar = this.router.url === '/';
              }
            }
          );
    }

    ngOnInit() {
    }

    public onClick(elementId: string): void { 
        this.viewportScroller.scrollToAnchor(elementId);
    }

    goToShop() {
        window.open("http://hoowspa.pl/", '_blank');
    }
}