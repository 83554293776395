<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container">
        <a class="navbar-brand" routerLink="/" (click)="onClick('home')">
           <img src="assets/img/logo-white.png" alt="logo">
             <img src="assets/img/logo2.png" alt="logo">
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" *ngIf="showNavbar" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                    <span class="nav-link flex" (click)="onClick('about')">
                        <img class="nav-ico" src="assets/icons/paw.svg" alt="img">
                        <span>O mnie</span>
                    </span></li>
                <li class="nav-item">
                    <span class="nav-link flex" (click)="onClick('different')">
                        <img class="nav-ico" src="assets/icons/scissors.svg" alt="img">
                        <span>Usługi</span>
                    </span></li>
                    <li class="nav-item"><span class="nav-link flex" (click)="onClick('cta')">
                        <img class="nav-ico" src="assets/icons/rules.svg" alt="img">
                        <span>Regulamin salonu</span>
                    </span></li>
                    <li class="nav-item"><span class="nav-link flex" (click)="onClick('services')">
                        <img class="nav-ico" src="assets/icons/money.svg" alt="img">
                        <span>Cennik</span>
                    </span></li>
                    <li class="nav-item"><span class="nav-link flex" (click)="onClick('blog')">
                    <img class="nav-ico" src="assets/icons/gallery.svg" alt="img">
                    <span>Psiaki</span>
                </span></li>
                <!-- <li class="nav-item"><span class="nav-link flex" routerLink="/psiblog">
                    <img class="nav-ico" src="assets/icons/blog.svg" alt="img">
                    <span>Psiblog</span>
                </span></li> -->
                <li class="nav-item"><span class="nav-link flex" (click)="goToShop()">
                    <img class="nav-ico" src="assets/icons/shop.svg" alt="img">
                    <span>Sklep</span>
                </span></li>
                <li class="nav-item"><span class="nav-link flex" (click)="onClick('contact')">
                    <img class="nav-ico" src="assets/icons/contact.svg" alt="img">
                    <span>Kontakt</span>
                </span></li>
            </ul>
        </div>
    </div>
</nav>