<footer class="footer-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-7">
            </div>
            <div class="col-lg-6 col-md-5">
                <ul>
                    <li><a href="https://www.facebook.com/groomerhelena" target="_blank"><i class="icofont-facebook"></i></a></li>
                    <li><a href="https://www.instagram.com/groomer_helena" target="_blank"><i class="icofont-instagram"></i></a></li>

                </ul>
            </div>
        </div>
    </div>
</footer>

<div class="go-top"><i class="icofont-swoosh-up"></i></div>

<app-demo-sidebar></app-demo-sidebar>