<!-- <section id="contact" class="contact-area ptb-100 bg-image">
    <div class="container">
        <div class="section-title">
            <h2>Kontakt</h2>
        </div>
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="contact-info">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <ul>
                                <li><i class="icofont-google-map"></i> <span>Lokalizacja:</span>ul. Krakowska 337, 43-300 Bielsko-Biała</li>
                                <li><i class="icofont-envelope"></i> <span>Email:</span><a href="mailto:kontakt@groomerhelena.pl">kontakt@groomerhelena.pl</a></li>
                                <li><i class="icofont-phone"></i> <span>Telefon:</span><a href="tel:+48792807561 ">(+48) 792-807-561 </a></li>
                                <li><i class="icofont-facebook"></i> <span>Facebook:</span><a href="https://www.facebook.com/groomerhelena" target="_blank">facebook.com/groomerhelena</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <iframe src="https://maps.google.com/maps?q=49.82607523327614,19.097999602697776&z=16&output=embed" height="100%" width="100%"></iframe>
            </div>
        </div>
    </div>
</section> -->
<section id="contact" class="contact-area ptb-100 bg-image">
    <div class="container">
        <div class="section-title">
            <h2>Kontakt</h2>
        </div>
        <div class="row">
                <div class="contact-info">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <ul>
                                <li><i class="icofont-envelope"></i> <span>Email:</span><a href="mailto:kontakt@groomerhelena.pl">kontakt@groomerhelena.pl</a></li>
                                <li><i class="icofont-phone"></i> <span>Telefon:</span><a href="tel:+48792807561 ">(+48) 792-807-561 </a></li>
                                <li><i class="icofont-facebook"></i> <span>Facebook:</span><a href="https://www.facebook.com/groomerhelena" target="_blank">facebook.com/groomerhelena</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</section>