<section id="blog" class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Psiaki</h2>
        </div>
        <div class="blog-slides owl-carousel owl-theme">
            <div class="single-blog-post">
                <div class="blog-image">
                    <a><img src="assets/img/pies1.jpeg" alt="image"></a>
                </div>
            </div>
            <div class="single-blog-post">
                <div class="blog-image">
                    <a><img src="assets/img/pies2.jpeg" alt="image"></a>
                </div>
            </div>
            <div class="single-blog-post">
                <div class="blog-image">
                    <a><img src="assets/img/pies3.jpeg" alt="image"></a>
                </div>
            </div>
            <div class="single-blog-post">
                <div class="blog-image">
                    <a><img src="assets/img/pies4.jpeg" alt="image"></a>
                </div>
            </div>
            <div class="single-blog-post">
                <div class="blog-image">
                    <a><img src="assets/img/pies5.jpeg" alt="image"></a>
                </div>
            </div>
            <div class="single-blog-post">
                <div class="blog-image">
                    <a><img src="assets/img/pies6.jpeg" alt="image"></a>
                </div>
            </div>
            <div class="single-blog-post">
                <div class="blog-image">
                    <a><img src="assets/img/pies7.jpeg" alt="image"></a>
                </div>
            </div>
            <div class="single-blog-post">
                <div class="blog-image">
                    <a><img src="assets/img/pies8.jpeg" alt="image"></a>
                </div>
            </div>
            <div class="single-blog-post">
                <div class="blog-image">
                    <a><img src="assets/img/pies9.jpeg" alt="image"></a>
                </div>
            </div>
            <div class="single-blog-post">
                <div class="blog-image">
                    <a><img src="assets/img/pies10.jpeg" alt="image"></a>
                </div>
            </div>
            <div class="single-blog-post">
                <div class="blog-image">
                    <a><img src="assets/img/pies11.jpeg" alt="image"></a>
                </div>
            </div>
            <div class="single-blog-post">
                <div class="blog-image">
                    <a><img src="assets/img/pies12.jpeg" alt="image"></a>
                </div>
            </div>
            <div class="single-blog-post">
                <div class="blog-image">
                    <a><img src="assets/img/pies13.jpeg" alt="image"></a>
                </div>
            </div>
            <div class="single-blog-post">
                <div class="blog-image">
                    <a><img src="assets/img/pies14.jpeg" alt="image"></a>
                </div>
            </div>
            <div class="single-blog-post">
                <div class="blog-image">
                    <a><img src="assets/img/pies15.jpeg" alt="image"></a>
                </div>
            </div>
            <div class="single-blog-post">
                <div class="blog-image">
                    <a><img src="assets/img/pies16.jpeg" alt="image"></a>
                </div>
            </div>
            <div class="single-blog-post">
                <div class="blog-image">
                    <a><img src="assets/img/pies17.jpeg" alt="image"></a>
                </div>
            </div>
        </div>
    </div>
</section>