<div class="main-banner item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content text-center">
                    <span class="wow fadeInUp" data-wow-delay="0.5s">We Are Creative</span>
                    <h1 class="wow fadeInUp" data-wow-delay="0.7s">The Best <b>Digital</b> Experience</h1>
                    <p class="wow fadeInUp" data-wow-delay="0.9s">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <div class="btn-box wow fadeInUp" data-wow-delay="1.1s">
                        <a href="#about" class="btn btn-primary">Get Started</a>
                        <span>or</span>
                        <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="icofont-ui-play"></i> Watch Video</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="features-area ptb-100 bg-image">
    <div class="container">
        <div class="section-title">
            <span class="wow fadeInUp" data-wow-delay="0.5s">Our Features</span>
            <h2 class="wow fadeInUp" data-wow-delay="0.7s">We Always Try To <b>Understand</b> Users Expectation</h2>
            <p class="wow fadeInUp" data-wow-delay="0.9s">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-features wow fadeInUp" data-wow-delay="0.5s">
                    <div class="icon">
                        <i class="icofont-edit"></i>
                    </div>
                    <h3>Creative Design</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam finibus velit nec.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-features active wow fadeInUp" data-wow-delay="0.7s">
                    <div class="icon">
                        <i class="icofont-upload-alt"></i>
                    </div>
                    <h3>Market Strategy</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam finibus velit nec.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-features wow fadeInUp" data-wow-delay="0.9s">
                    <div class="icon">
                        <i class="icofont-pie-chart"></i>
                    </div>
                    <h3>Business Growth</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam finibus velit nec.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="about" class="about-area ptb-100 bg-image">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="row about-image wow fadeInUp" data-wow-delay="0.5s">
                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image">
                            <img src="assets/img/about-img1.jpg" alt="about">
                        </div>
                    </div>
                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image">
                            <img src="assets/img/about-img2.jpg" alt="about">
                        </div>
                    </div>
                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image mt-30">
                            <img src="assets/img/about-img3.jpg" alt="about">
                        </div>
                    </div>
                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image mt-30">
                            <img src="assets/img/about-img4.jpg" alt="about">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content wow fadeInUp" data-wow-delay="0.7s">
                    <span>About Avurn</span>
                    <h2>We Are Digital <b>Marketing</b> & Branding</h2>
                    <p>We provide marketing services to startups and small businesses to looking for a partner of their digital media, design & development, lead generation and communications requirents. We work with you, not for you. Although we have a great resources.</p>
                    <ul>
                        <li>Creative Design</li>
                        <li>Modern Design</li>
                        <li>Retina Ready</li>
                        <li>Awesome Design</li>
                        <li>Responsive Design</li>
                    </ul>
                    <p>We are an experienced and talented team of passionate consultants who live and breathe search engine marketing</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="cta-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-8">
                <div class="cta-content">
                    <h3 class="wow fadeInUp" data-wow-delay="0.5s">Start to build your <b>awesome</b> websites</h3>
                    <span class="wow fadeInUp" data-wow-delay="0.7s">Create lovely & smart websites faster! Get Avurn now!</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 text-right">
                <a href="#contact" class="btn btn-primary wow fadeInUp" data-wow-delay="0.7s">Start a Project</a>
            </div>
        </div>
    </div>
</section>

<section id="services" class="services-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="wow fadeInUp" data-wow-delay="0.5s">What We Do</span>
            <h2 class="wow fadeInUp" data-wow-delay="0.7s">Our <b>Services</b></h2>
            <p class="wow fadeInUp" data-wow-delay="0.9s">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services wow fadeInUp" data-wow-delay="0.5s">
                    <div class="services-image">
                        <img src="assets/img/services-img1.jpg" alt="image">
                        <div class="icon">
                            <i class="icofont-headphone-alt-3"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Web Design</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam finibus velit nec.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services wow fadeInUp" data-wow-delay="0.7s">
                    <div class="services-image">
                        <img src="assets/img/services-img2.jpg" alt="image">
                        <div class="icon">
                            <i class="icofont-anchor"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Branding Design</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam finibus velit nec.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services wow fadeInUp" data-wow-delay="0.9s">
                    <div class="services-image">
                        <img src="assets/img/services-img3.jpg" alt="image">
                        <div class="icon">
                            <i class="icofont-puzzle"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Digital Marketing</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam finibus velit nec.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services wow fadeInUp" data-wow-delay="0.5s">
                    <div class="services-image">
                        <img src="assets/img/services-img4.jpg" alt="image">
                        <div class="icon">
                            <i class="icofont-tools"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>App Development</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam finibus velit nec.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services wow fadeInUp" data-wow-delay="0.7s">
                    <div class="services-image">
                        <img src="assets/img/services-img5.jpg" alt="image">
                        <div class="icon">
                            <i class="icofont-settings-alt"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Easy Customization</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam finibus velit nec.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services wow fadeInUp" data-wow-delay="0.9s">
                    <div class="services-image">
                        <img src="assets/img/services-img6.jpg" alt="image">
                        <div class="icon">
                            <i class="icofont-puzzle"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Fully Responsive</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam finibus velit nec.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="who-we-are ptb-100 bg-image">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="section-title">
                    <span class="wow fadeInUp" data-wow-delay="0.5s">Who We Are</span>
                    <h2 class="wow fadeInUp" data-wow-delay="0.7s">We stand out of the <b>Avurn</b></h2>
                    <p class="wow fadeInUp" data-wow-delay="0.9s">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <a href="#portfolio" class="btn btn-primary wow fadeInUp" data-wow-delay="1.1s">Our Work</a>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="content">
                    <p class="wow fadeInUp" data-wow-delay="0.5s">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                    <ul class="wow fadeInUp" data-wow-delay="0.7s">
                        <li>We Are Professional</li>
                        <li>We Are Passionate</li>
                        <li>We Are Creative</li>
                        <li>We Are Designer</li>
                        <li>We Are Unique</li>
                        <li>We Are Support</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="skill-area">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="skill-content ptb-100">
                    <div class="section-title">
                        <span class="wow fadeInUp" data-wow-delay="0.5s">We Are Awesome</span>
                        <h2 class="wow fadeInUp" data-wow-delay="0.7s">Some Of Our <b>Skills</b></h2>
                        <p class="wow fadeInUp" data-wow-delay="0.9s">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                    <div class="skills">
                        <div class="skill-item wow fadeInUp" data-wow-delay="0.5s">
                            <div class="skill-header">
                                <h3 class="skill-title">Branding</h3>
                                <div class="skill-percentage">
                                    <div class="count-box"><span class="count-text" data-speed="2000" data-stop="95">0</span>%</div>
                                </div>
                            </div>
                            <div class="skill-bar">
                                <div class="bar-inner"><div class="bar progress-line" data-width="95"></div></div>
                            </div>
                        </div>
                        <div class="skill-item wow fadeInUp" data-wow-delay="0.7s">
                            <div class="skill-header">
                                <h3 class="skill-title">Marketing</h3>
                                <div class="skill-percentage"><div class="count-box"><span class="count-text" data-speed="2000" data-stop="90">0</span>%</div></div>
                            </div>
                            <div class="skill-bar">
                                <div class="bar-inner"><div class="bar progress-line" data-width="90"></div></div>
                            </div>
                        </div>
                        <div class="skill-item wow fadeInUp" data-wow-delay="0.9s">
                            <div class="skill-header">
                                <h3 class="skill-title">Design</h3>
                                <div class="skill-percentage"><div class="count-box"><span class="count-text" data-speed="2000" data-stop="95">0</span>%</div></div>
                            </div>
                            <div class="skill-bar">
                                <div class="bar-inner"><div class="bar progress-line" data-width="95"></div></div>
                            </div>
                        </div>
                        <div class="skill-item wow fadeInUp" data-wow-delay="1.1s">
                            <div class="skill-header">
                                <h3 class="skill-title">Development</h3>
                                <div class="skill-percentage"><div class="count-box"><span class="count-text" data-speed="2000" data-stop="93">0</span>%</div></div>
                            </div>
                            <div class="skill-bar">
                                <div class="bar-inner"><div class="bar progress-line" data-width="93"></div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 p-0">
                <div class="skill-image">
                </div>
            </div>
        </div>
    </div>
</section>

<section id="portfolio" class="portfolio-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="wow fadeInUp" data-wow-delay="0.5s">Our Latest Work</span>
            <h2 class="wow fadeInUp" data-wow-delay="0.7s">Our <b>Portfolio</b></h2>
            <p class="wow fadeInUp" data-wow-delay="0.9s">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="shorting-menu wow fadeInUp" data-wow-delay="1.1s">
            <button class="filter" data-filter="all">All</button>
            <button class="filter" data-filter=".design">Design</button>
            <button class="filter" data-filter=".seo">SEO</button>
            <button class="filter" data-filter=".branding">Branding</button>
            <button class="filter" data-filter=".print">Print</button>
            <button class="filter" data-filter=".video">Video</button>
        </div>
        <div class="shorting">
            <div class="row">
                <div class="col-lg-4 col-md-6 mix design">
                    <div class="single-work wow fadeInUp" data-wow-delay="0.5s">
                        <a href="assets/img/work-img1.jpg" class="popup-btn"></a>
                        <div class="work-image">
                            <img src="assets/img/work-img1.jpg" alt="work-image">
                            <div class="work-overlay">
                                <h3>Elegant Mockup</h3>
                                <span>UI/UX</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mix print seo">
                    <div class="single-work wow fadeInUp" data-wow-delay="0.7s">
                        <a href="assets/img/work-img2.jpg" class="popup-btn"></a>
                        <div class="work-image">
                            <img src="assets/img/work-img2.jpg" alt="work-image">
                            <div class="work-overlay">
                                <h3>Internet Concept</h3>
                                <span>Psd</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mix video branding">
                    <div class="single-work wow fadeInUp" data-wow-delay="0.9s">
                        <a href="assets/img/work-img3.jpg" class="popup-btn"></a>
                        <div class="work-image">
                            <img src="assets/img/work-img3.jpg" alt="work-image">
                            <div class="work-overlay">
                                <h3>Mupi Mockup</h3>
                                <span>Pencil</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mix design print">
                    <div class="single-work wow fadeInUp" data-wow-delay="0.5s">
                        <a href="assets/img/work-img4.jpg" class="popup-btn"></a>
                        <div class="work-image">
                            <img src="assets/img/work-img4.jpg" alt="work-image">
                            <div class="work-overlay">
                                <h3>Premium Template</h3>
                                <span>Art</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mix branding video">
                    <div class="single-work wow fadeInUp" data-wow-delay="0.7s">
                        <a href="assets/img/work-img5.jpg" class="popup-btn"></a>
                        <div class="work-image">
                            <img src="assets/img/work-img5.jpg" alt="work-image">
                            <div class="work-overlay">
                                <h3>Pencil Art</h3>
                                <span>Business</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mix seo design">
                    <div class="single-work wow fadeInUp" data-wow-delay="0.9s">
                        <a href="assets/img/work-img6.jpg" class="popup-btn"></a>
                        <div class="work-image">
                            <img src="assets/img/work-img6.jpg" alt="work-image">
                            <div class="work-overlay">
                                <h3>Front View Psd</h3>
                                <span>Design</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="why-we-different ptb-100 bg-image">
    <div class="container">
        <div class="section-title">
            <span class="wow fadeInUp" data-wow-delay="0.5s">Highly Creative Solutions</span>
            <h2 class="wow fadeInUp" data-wow-delay="0.7s">Why we are <b>Different</b></h2>
            <p class="wow fadeInUp" data-wow-delay="0.9s">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="tab">
            <ul class="tabs wow fadeInUp" data-wow-delay="1.1s">
                <li><a href="#">
                    Speed , Flexibility
                </a></li>
                <li><a href="#">
                    Profeesional work
                </a></li>
                <li><a href="#">
                    Experienced staff
                </a></li>
                <li><a href="#">
                    Customer support
                </a></li>
            </ul>
            <div class="tab_content wow fadeInUp" data-wow-delay="1.3s">
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12 why-we-different-content">
                            <div class="tabs_item_content">
                                <h3>Speed , Flexibility</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>
                                <ul>
                                    <li>Creative Design</li>
                                    <li>Retina Ready</li>
                                    <li>Modern Design</li>
                                    <li>Digital Marketing & Branding</li>
                                    <li>Awesome Design</li>
                                    <li>We Are Professional</li>
                                    <li>We Are Creative</li>
                                    <li>We Are Unique</li>
                                </ul>
                                <a href="#portfolio" class="btn btn-primary">Our Work</a>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 why-we-different-img">
                            <div class="tabs_item_img">
                                <img src="assets/img/why-we-different1.jpg" alt="img">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12 why-we-different-img">
                            <div class="tabs_item_img">
                                <img src="assets/img/why-we-different2.jpg" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 why-we-different-content">
                            <div class="tabs_item_content">
                                <h3>Profeesional Work</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>
                                <ul>
                                    <li>Creative Design</li>
                                    <li>Retina Ready</li>
                                    <li>Modern Design</li>
                                    <li>Digital Marketing & Branding</li>
                                    <li>Awesome Design</li>
                                    <li>We Are Professional</li>
                                    <li>We Are Creative</li>
                                    <li>We Are Unique</li>
                                </ul>
                                <a href="#portfolio" class="btn btn-primary">Our Work</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12 why-we-different-content">
                            <div class="tabs_item_content">
                                <h3>Experience Staff</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>
                                <ul>
                                    <li>Creative Design</li>
                                    <li>Retina Ready</li>
                                    <li>Modern Design</li>
                                    <li>Digital Marketing & Branding</li>
                                    <li>Awesome Design</li>
                                    <li>We Are Professional</li>
                                    <li>We Are Creative</li>
                                    <li>We Are Unique</li>
                                </ul>
                                <a href="#portfolio" class="btn btn-primary">Our Work</a>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 why-we-different-img">
                            <div class="tabs_item_img">
                                <img src="assets/img/why-we-different3.jpg" alt="img">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12 why-we-different-img">
                            <div class="tabs_item_img">
                                <img src="assets/img/why-we-different4.jpg" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 why-we-different-content">
                            <div class="tabs_item_content">
                                <h3>24/7 Support</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>
                                <ul>
                                    <li>Creative Design</li>
                                    <li>Retina Ready</li>
                                    <li>Modern Design</li>
                                    <li>Digital Marketing & Branding</li>
                                    <li>Awesome Design</li>
                                    <li>We Are Professional</li>
                                    <li>We Are Creative</li>
                                    <li>We Are Unique</li>
                                </ul>
                                <a href="#portfolio" class="btn btn-primary">Our Work</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="funfacts-area-two ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3">
                <div class="single-funfact wow fadeInUp" data-wow-delay="0.5s">
                    <i class="icofont-checked"></i>
                    <h3 class="odometer" data-count="820">00</h3>
                    <p>Successfull Projects</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3">
                <div class="single-funfact wow fadeInUp" data-wow-delay="0.7s">
                    <i class="icofont-ui-user"></i>
                    <h3 class="odometer" data-count="6523">00</h3>
                    <p>Satisfied Customers</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3">
                <div class="single-funfact wow fadeInUp" data-wow-delay="0.9s">
                    <i class="icofont-win-trophy"></i>
                    <h3 class="odometer" data-count="50">00</h3>
                    <p>Awards Wining</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3">
                <div class="single-funfact wow fadeInUp" data-wow-delay="1.1s">
                    <i class="icofont-crown-king"></i>
                    <h3 class="odometer" data-count="20">00</h3>
                    <p>Year Of Experience</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="team" class="team-area ptb-100 bg-image">
    <div class="container">
        <div class="section-title">
            <span class="wow fadeInUp" data-wow-delay="0.5s">Meet Our Expert</span>
            <h2 class="wow fadeInUp" data-wow-delay="0.7s">Our Creative <b>Team</b></h2>
            <p class="wow fadeInUp" data-wow-delay="0.9s">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="team-slides owl-carousel owl-theme wow fadeInUp" data-wow-delay="1.1s">
            <div class="team-box">
                <img src="assets/img/team-img1.jpg" alt="team-image">
                <div class="box-content">
                    <div class="box-inner-content">
                        <h3 class="title">Steven Smitn</h3>
                        <span class="post">Web Developer</span>
                        <ul>
                            <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="team-box">
                <img src="assets/img/team-img2.jpg" alt="team-image">
                <div class="box-content">
                    <div class="box-inner-content">
                        <h3 class="title">Alina Eva</h3>
                        <span class="post">UI/UX Designer</span>
                        <ul>
                            <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="team-box">
                <img src="assets/img/team-img3.jpg" alt="team-image">
                <div class="box-content">
                    <div class="box-inner-content">
                        <h3 class="title">James Anderson</h3>
                        <span class="post">Photographer</span>
                        <ul>
                            <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="team-box">
                <img src="assets/img/team-img4.jpg" alt="team-image">
                <div class="box-content">
                    <div class="box-inner-content">
                        <h3 class="title">Eva Lucy</h3>
                        <span class="post">Web Designer</span>
                        <ul>
                            <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="story-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="story-content">
            <div class="section-title">
                <span class="wow fadeInUp" data-wow-delay="0.5s">Our Story</span>
                <h2 class="wow fadeInUp" data-wow-delay="0.7s">We are always with you to <b>make</b> your project success</h2>
                <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube wow fadeInUp" data-wow-delay="0.9s"><i class="icofont-ui-play"></i></a>
            </div>
        </div>
    </div>
</section>

<section id="pricing" class="pricing-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="wow fadeInUp" data-wow-delay="0.5s">Latest and Best</span>
            <h2 class="wow fadeInUp" data-wow-delay="0.7s">Our <b>Pricing</b></h2>
            <p class="wow fadeInUp" data-wow-delay="0.9s">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="tab-slider-nav wow fadeInUp" data-wow-delay="1.1s">
            <ul class="tab-slider-tabs">
                <li class="tab-slider-trigger active" rel="monthly">Monthly</li>
                <li class="tab-slider-trigger" rel="yearly">Yearly</li>
            </ul>
        </div>
        <div class="tab-slider-container wow fadeInUp" data-wow-delay="1.3s">
            <div id="monthly" class="tab-slider-body">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="pricing-table">
                            <div class="pricing-header">
                                Basic
                            </div>
                            <div class="price">
                                <sup>$</sup>145.99
                            </div>
                            <ul class="pricing-features">
                                <li><b>10 GB</b> Hosting</li>
                                <li>2 Unique Users</li>
                                <li><b>12 GB</b> Capacity</li>
                                <li>Weekly Backups</li>
                                <li><b>15 Domain</b> Names</li>
                                <li>Support <b>24/7</b></li>
                            </ul>
                            <a href="#" class="btn btn-primary">Purchase Now</a>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="pricing-table">
                            <div class="pricing-header">
                                Standard
                            </div>
                            <div class="price">
                                <sup>$</sup>250.99
                            </div>
                            <ul class="pricing-features">
                                <li><b>15 GB</b> Hosting</li>
                                <li>5 Unique Users</li>
                                <li><b>15 GB</b> Capacity</li>
                                <li>Weekly Backups</li>
                                <li><b>20 Domain</b> Names</li>
                                <li>Support <b>24/7</b></li>
                            </ul>
                            <a href="#" class="btn btn-primary">Purchase Now</a>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                        <div class="pricing-table">
                            <div class="pricing-header">
                                Premium
                            </div>
                            <div class="price">
                                <sup>$</sup>325.99
                            </div>
                            <ul class="pricing-features">
                                <li><b>20 GB</b> Hosting</li>
                                <li>7 Unique Users</li>
                                <li><b>20 GB</b> Capacity</li>
                                <li>Weekly Backups</li>
                                <li><b>25 Domain</b> Names</li>
                                <li>Support <b>24/7</b></li>
                            </ul>
                            <a href="#" class="btn btn-primary">Purchase Now</a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="yearly" class="tab-slider-body">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="pricing-table">
                            <div class="pricing-header">
                                Basic
                            </div>
                            <div class="price">
                                <sup>$</sup>245.99
                            </div>
                            <ul class="pricing-features">
                                <li><b>10 GB</b> Hosting</li>
                                <li>2 Unique Users</li>
                                <li><b>12 GB</b> Capacity</li>
                                <li>Weekly Backups</li>
                                <li><b>15 Domain</b> Names</li>
                                <li>Support <b>24/7</b></li>
                            </ul>
                            <a href="#" class="btn btn-primary">Purchase Now</a>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="pricing-table">
                            <div class="pricing-header">
                                Standard
                            </div>
                            <div class="price">
                                <sup>$</sup>350.99
                            </div>
                            <ul class="pricing-features">
                                <li><b>15 GB</b> Hosting</li>
                                <li>5 Unique Users</li>
                                <li><b>15 GB</b> Capacity</li>
                                <li>Weekly Backups</li>
                                <li><b>20 Domain</b> Names</li>
                                <li>Support <b>24/7</b></li>
                            </ul>
                            <a href="#" class="btn btn-primary">Purchase Now</a>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                        <div class="pricing-table">
                            <div class="pricing-header">
                                Premium
                            </div>
                            <div class="price">
                                <sup>$</sup>525.99
                            </div>
                            <ul class="pricing-features">
                                <li><b>20 GB</b> Hosting</li>
                                <li>7 Unique Users</li>
                                <li><b>20 GB</b> Capacity</li>
                                <li>Weekly Backups</li>
                                <li><b>25 Domain</b> Names</li>
                                <li>Support <b>24/7</b></li>
                            </ul>
                            <a href="#" class="btn btn-primary">Purchase Now</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="testimonial" class="testimonial-area ptb-100 bg-image">
    <div class="container">
        <div class="section-title">
            <span class="wow fadeInUp" data-wow-delay="0.5s">What people say about us</span>
            <h2 class="wow fadeInUp" data-wow-delay="0.7s">Our <b>Testimonial</b></h2>
            <p class="wow fadeInUp" data-wow-delay="0.9s">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="testimonial-slides owl-carousel owl-theme wow fadeInUp" data-wow-delay="1.1s">
            <div class="testimonial-item">
                <div class="client-image">
                    <img src="assets/img/author1.jpg" alt="image">
                </div>
                <div class="testimonial-content">
                    <div class="desc">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed malesuada vulputate nisi in fermentum. Vivamus ac libero quis nisi auctor pulvinar. Aenean sit amet lectus posuere, mattis massa eget, ullamcorper diam. Nunc sit amet felis eget arcu congue dictum.</p>
                    </div>
                    <div class="client-info">
                        <h3>John Smith</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>
            <div class="testimonial-item">
                <div class="client-image">
                    <img src="assets/img/author2.jpg" alt="image">
                </div>
                <div class="testimonial-content">
                    <div class="desc">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed malesuada vulputate nisi in fermentum. Vivamus ac libero quis nisi auctor pulvinar. Aenean sit amet lectus posuere, mattis massa eget, ullamcorper diam. Nunc sit amet felis eget arcu congue dictum.</p>
                    </div>
                    <div class="client-info">
                        <h3>Steven Smith</h3>
                        <span>Product Manager</span>
                    </div>
                </div>
            </div>
            <div class="testimonial-item">
                <div class="client-image">
                    <img src="assets/img/author3.jpg" alt="image">
                </div>
                <div class="testimonial-content">
                    <div class="desc">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed malesuada vulputate nisi in fermentum. Vivamus ac libero quis nisi auctor pulvinar. Aenean sit amet lectus posuere, mattis massa eget, ullamcorper diam. Nunc sit amet felis eget arcu congue dictum.</p>
                    </div>
                    <div class="client-info">
                        <h3>Alina Eva</h3>
                        <span>Web Designer</span>
                    </div>
                </div>
            </div>
            <div class="testimonial-item">
                <div class="client-image">
                    <img src="assets/img/author4.jpg" alt="image">
                </div>
                <div class="testimonial-content">
                    <div class="desc">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed malesuada vulputate nisi in fermentum. Vivamus ac libero quis nisi auctor pulvinar. Aenean sit amet lectus posuere, mattis massa eget, ullamcorper diam. Nunc sit amet felis eget arcu congue dictum.</p>
                    </div>
                    <div class="client-info">
                        <h3>Steven Smith</h3>
                        <span>Product Manager</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="call-to-action ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="call-to-action-content">
            <div class="section-title">
                <span class="wow fadeInUp" data-wow-delay="0.5s">We are Best Company</span>
                <h2 class="wow fadeInUp" data-wow-delay="0.7s">Ready To <b>Work</b> With Us</h2>
                <p class="wow fadeInUp" data-wow-delay="0.9s">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                <a href="#contact" class="btn btn-primary wow fadeInUp" data-wow-delay="1.1s">Contact Us</a>
            </div>
        </div>
    </div>
</section>

<section class="faq-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="wow fadeInUp" data-wow-delay="0.5s">FAQ</span>
            <h2 class="wow fadeInUp" data-wow-delay="0.7s">Frequently Asked <b>Question</b></h2>
            <p class="wow fadeInUp" data-wow-delay="0.9s">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="faq-accordion wow fadeInUp" data-wow-delay="0.5s" id="accordionEx" role="tablist" aria-multiselectable="true">
                    <div class="card">
                        <div class="card-header" role="tab" id="headingOne">
                            <a data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                What’s the difference between a college and a university? <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseOne" class="collapse show" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordionEx">
                            <div class="card-body">
                                Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingTwo">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                What are the different types of undergraduate degrees? <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo" data-parent="#accordionEx">
                            <div class="card-body">
                                Associate: a two-year program that either leads to a specific vocation or transitions to a bachelor program. Bachelor: a four or five-year program where students earn credits in a wide variety of courses.
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingThree">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                What are the different types of graduate degrees? <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseThree" class="collapse" role="tabpanel" aria-labelledby="headingThree" data-parent="#accordionEx">
                            <div class="card-body">
                                Masters: two-year degree providing additional specialization. Doctorate: five to eight-year program certifying the student as a trained research scholar and/or professor.
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingFour">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                Can you work while studying in the United States? <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseFour" class="collapse" role="tabpanel" aria-labelledby="headingFour" data-parent="#accordionEx">
                            <div class="card-body">
                                With permission of the International Student Office, international students may work on campus up to 20 hours/week their first year and can apply to work off-campus in subsequent years.
                            </div>
                        </div>
                    </div>
                    <div class="card mb-0">
                        <div class="card-header" role="tab" id="headingFive">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                What is distance education? <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseFive" class="collapse" role="tabpanel" aria-labelledby="headingFive" data-parent="#accordionEx">
                            <div class="card-body">
                                Distance education occurs when a student and an instructor are in different places. Learning occurs by mail, telephone, internet, or by other means.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="faq-image wow fadeInUp" data-wow-delay="0.7s">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="partner-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="wow fadeInUp" data-wow-delay="0.5s">Trusted Client</span>
            <h2 class="wow fadeInUp" data-wow-delay="0.7s">Our Happy <b>Partners</b></h2>
            <p class="wow fadeInUp" data-wow-delay="0.9s">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="partner-slides owl-carousel owl-theme wow fadeInUp" data-wow-delay="1.1s">
            <div class="partner-item">
                <a href="#">
                    <img src="assets/img/partner1.png" alt="img">
                </a>
            </div>
            <div class="partner-item">
                <a href="#">
                    <img src="assets/img/partner2.png" alt="img">
                </a>
            </div>
            <div class="partner-item">
                <a href="#">
                    <img src="assets/img/partner3.png" alt="img">
                </a>
            </div>
            <div class="partner-item">
                <a href="#">
                    <img src="assets/img/partner4.png" alt="img">
                </a>
            </div>
            <div class="partner-item">
                <a href="#">
                    <img src="assets/img/partner5.png" alt="img">
                </a>
            </div>
            <div class="partner-item">
                <a href="#">
                    <img src="assets/img/partner6.png" alt="img">
                </a>
            </div>
        </div>
    </div>
</section>

<section id="blog" class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="wow fadeInUp" data-wow-delay="0.5s">Info Update!</span>
            <h2 class="wow fadeInUp" data-wow-delay="0.7s">Our Latest <b>News</b></h2>
            <p class="wow fadeInUp" data-wow-delay="0.9s">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="blog-slides owl-carousel owl-theme wow fadeInUp" data-wow-delay="1.1s">
            <div class="single-blog-post">
                <div class="blog-image">
                    <a href="#"><img src="assets/img/blog1.jpg" alt="image"></a>
                    <div class="post-tag">
                        <a href="#">Technology</a>
                    </div>
                </div>
                <div class="blog-post-content">
                    <span class="date">25 Feb, 2020</span>
                    <h3><a href="#">The most popular new top business apps</a></h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum printing typesetting.</p>
                    <a href="#" class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                </div>
            </div>
            <div class="single-blog-post">
                <div class="blog-image">
                    <a href="#"><img src="assets/img/blog2.jpg" alt="image"></a>
                    <div class="post-tag">
                        <a href="#">Agency</a>
                    </div>
                </div>
                <div class="blog-post-content">
                    <span class="date">27 Feb, 2020</span>
                    <h3><a href="#">The best marketing top use management tools</a></h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum printing typesetting.</p>
                    <a href="#" class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                </div>
            </div>
            <div class="single-blog-post">
                <div class="blog-image">
                    <a href="#"><img src="assets/img/blog3.jpg" alt="image"></a>
                    <div class="post-tag">
                        <a href="#">IT</a>
                    </div>
                </div>
                <div class="blog-post-content">
                    <span class="date">28 Feb, 2020</span>
                    <h3><a href="#">3 wooCommerce plugins to boost sales</a></h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum printing typesetting.</p>
                    <a href="#" class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                </div>
            </div>
            <div class="single-blog-post">
                <div class="blog-image">
                    <a href="#"><img src="assets/img/blog4.jpg" alt="image"></a>
                    <div class="post-tag">
                        <a href="#">Creative</a>
                    </div>
                </div>
                <div class="blog-post-content">
                    <span class="date">29 Feb, 2020</span>
                    <h3><a href="#">Top 21 must-read blogs for creative agencies</a></h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum printing typesetting.</p>
                    <a href="#" class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="contact" class="contact-area ptb-100 bg-image">
    <div class="container">
        <div class="section-title">
            <span class="wow fadeInUp" data-wow-delay="0.5s">Get In Touch</span>
            <h2 class="wow fadeInUp" data-wow-delay="0.7s">Let's <b>Contact</b> Us</h2>
            <p class="wow fadeInUp" data-wow-delay="0.9s">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="contact-info wow fadeInUp" data-wow-delay="1.1s">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <ul>
                                <li><i class="icofont-google-map"></i> <span>Location:</span>Exercisplan 4, 111 49 Stockholm, Sweden</li>
                                <li><i class="icofont-envelope"></i> <span>Email:</span><a href="mailto:hello@avurn.com">hello@avurn.com</a></li>
                                <li><i class="icofont-phone"></i> <span>Call:</span><a href="tel:+1244122445515">(+124) 412-2445515</a></li>
                                <li><i class="icofont-fax"></i> <span>Fax:</span><a href="tel:+61724160055">+617-241-60055</a></li>
                                <li><i class="icofont-facebook"></i> <span>Facebook:</span><a href="https://facebook.com/" target="_blank">facebook.com/avurn</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="contact-form wow fadeInUp" data-wow-delay="1.3s">
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Name">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Email Address">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Subject">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Phone Number">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea placeholder="Message" class="form-control" cols="30" rows="5"></textarea>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="btn btn-primary">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>