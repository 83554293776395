<section class="page-title-area item-bg1">
    <div class="container">
        <h2>Psiblog</h2>
    </div>
</section>

<section class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a routerLink="/trymowanie"><img src="assets/img/trymowanie.jpeg" alt="image"></a>
                        <div class="post-tag">
                            <a routerLink="#">Trymowanko</a>
                        </div>
                    </div>
                    <div class="blog-post-content">
                        <h3><a routerLink="/trymowanie">Rach ciach ciach tytuł</a></h3>
                        <p>Rach trach ciach - krótki opis</p>
                        <a routerLink="/trymowanie" class="read-more-btn">Zobacz więcej <i class="icofont-double-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>