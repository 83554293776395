<section id="about" class="about-area ptb-100 bg-image">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="row about-image">
                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image">
                            <img src="assets/img/about-img1.jpeg" alt="about">
                        </div>
                    </div>

                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image">
                            <img src="assets/img/about-img2.jpeg" alt="about">
                        </div>
                    </div>

                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image mt-30">
                            <img src="assets/img/about-img3.jpeg" alt="about">
                        </div>
                    </div>

                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image mt-30">
                            <img src="assets/img/about-img4.jpeg" alt="about">
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>O mnie</span>
                    <h2 style="color: rgb(19, 80, 153)">Nazywam się Helena i zajmuję się groomingiem od 2020 roku.</h2>
                    <p>Po zdobyciu pierwszego dyplomu wiedziałam że to jest moja nowa pasja. Wpadłam po uszy w
                        grooming. Nieustannie doskonalę umiejętności oraz wiedzę uczestnicząc w seminariach i szkoleniach
                        z najlepszymi w Polsce i zagranicą.</p>
                        <p>Kładę nacisk w mojej pracy na jakość a nie ilość. Do każdego klienta podchodzę indywidualnie i
                            przeznaczam taką ilość czasu aby psiak czuł się bezpiecznie i komfortowo bez zbędnego stresu.</p>
                            <p style="color: rgb(19, 80, 153)"><b>Czym jest grooming?</b></p>
                            <p>Grooming to inaczej kompleksowa pielęgnacja psów, strzyżenie, kąpiele i zabiegi, które sprawiają że
                                psiak nie tylko ładnie wygląda ale również czuje się komfortowo. Czyszczenie uszu, skracanie
                                pazurów, pozbywanie się martwej sierści, kołtunów czy filcy to właśnie robi groomer nie tylko ładnie
                                strzyże.</p>
                                <p style="color: rgb(19, 80, 153)"><b>Czym grooming jest dla mnie?</b></p>
                                <p>Okazało się że jest to ciężka ale satysfakcjonująca praca,
                                    zawód, który można dobrze wykonywać jedynie jeśli jest pasją
                                    i pasja , w której utonęłam i pochłonęła mój cały wolny czas poza pracą.</p>
                </div>
            </div>
        </div>
    </div>
</section>